import type { ReactElement } from "react";
import { ColorShiftTextAnimator } from "@uxu/design-system/components/atoms/colorShiftTextAnimator";
import { Link } from "@uxu/design-system/components/atoms/link";
import styles from './sectionMediaCoverage.module.scss';
import { header, description, buttonLeft, buttonRight } from './consts';

export function SectionMediaCoverage(): ReactElement {
    return (
        <section className={styles.section}>
            <ColorShiftTextAnimator description={description} header={header} />
            <div className={styles.wrapperButtons}>
                <Link className="btn primary large" href="#start" title="Sprawdź możliwy zwrot">{buttonLeft}</Link>
                <Link className="btn special large" href="#analize" title="Jak to działa ?"><span/><span/><span/>{buttonRight}</Link>
            </div>
        </section>)
}